import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { icons } from '../../../assets/svg/icons/index.js';
import {
  AmountOfFilters,
  BadgeContainer,
  ButtonContainer,
  FilterContainer,
  FilterItem,
  FilterName,
  FiltersList,
  FilterType,
  Item,
  MainList,
  NoResultsMsg,
  Scroll,
  SearchContainer,
  SearchInput,
  ShowMore
} from './FilterPanel.js';
import Checkbox from '../checkbox/checkbox.jsx';
import { Badge } from '../badge/badge.jsx';
import useIBIS from '../../hooks/useIbis.js';
import { parseHints } from '../../utils/hints.js';

const FilterPanel = ({ customUrl, modal, setTarget }) => {
  const checkboxRef = useRef(null);
  const { t } = useTranslation();
  const location = useLocation();
  const pathname = decodeURIComponent(location.pathname);
  const history = useHistory();

  const [ path, setPath ] = useState( customUrl ? pathname + customUrl : null);
  const [ data, setData ] = useState([]);

  const IBIS = useIBIS(!!path, path);

  const badges = data?.find((el) => el?.type === "buttons_panel");

  const [ search, setSearch ] = useState("");
  const [ isOpenTooltipId, setIsOpenTooltipId ] = useState(null);
  const [ isTextOverflow, setIsTextOverflow ] = useState(false);
  const [ expanded, setExpanded ] = useState(false);

  const handleShowMore = (index) => {
    setExpanded(prevExpanded => ({
      ...prevExpanded,
      [index]: !prevExpanded[index],
    }));
  };

  useEffect(() => {
    if (!IBIS.isLoading) {
      setData(parseHints(IBIS?.node?.hints?.navbar?.sections));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ IBIS.isLoading ]);

  const toggle = (id) => setIsOpenTooltipId(isOpenTooltipId === id ? null : id);

  function relativePathname(url1, url2) {
    const parsedUrl1 = new URL(url1);
    const parsedUrl2 = new URL(url2);

    const pathname1 = parsedUrl1.pathname;
    const pathname2 = parsedUrl2.pathname;

    const parts1 = pathname1.split("/").filter(Boolean);
    const parts2 = pathname2.split("/").filter(Boolean);

    let i = 0;
    while (i < parts1.length && i < parts2.length && parts1[i] === parts2[i]) {
      i++;
    }

    let relativePath = "";
    for (let j = i; j < parts1.length; j++) {
      relativePath += "../";
    }
    relativePath += parts2.slice(i).join("/");
    return relativePath;
  }

  const handleKeyDown = (event, key) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      document.getElementById(key).click();
    }
  };

  const toggleSubmit = (newPath) => {
    if (newPath) {
      if (customUrl) {
        setPath(path + newPath);

        const finalTarget = relativePathname(
          window.location.href + pathname,
          window.location.href + path + newPath
        );
        setTarget(finalTarget);
      }
      else history.push(newPath);
    }
  };

  const checkTextOverflow = (elementId) => {
    const element = document.getElementById(elementId);
    setIsTextOverflow(element && element.offsetWidth < element.scrollWidth);
  };

  const filterDataBySearch = (data, search) => {
    const enum_panel_data = data.filter((item) => item?.type === "enum_panel");
    if (!search) return enum_panel_data;

    return enum_panel_data
      .filter((item) =>
        Object.keys(item.options).some((key) =>
          item.options[key].label.toLowerCase().includes(search.toLowerCase())
        )
      )
      .map((item) => ({
        ...item,
        options: Object.keys(item.options)
          .filter((key) =>
            item.options[key].label.toLowerCase().includes(search.toLowerCase())
          )
          .reduce((obj, key) => {
            obj[key] = item.options[key];
            return obj;
          }, {}),
      }));
  };

  const sortByPriority = (data) => {
    return data.sort((a, b) => a.priority - b.priority);
  };

  const filteredData = sortByPriority(filterDataBySearch(data, search));

  const formatString = (str) => {
    let formattedStr = str.replace(/[-_]/g, " ");
    formattedStr = formattedStr
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return formattedStr;
  };

  const CustomTooltip = (target, text) => {
    return (
      <Tooltip
        isOpen={isOpenTooltipId === target}
        toggle={() => toggle(null)}
        target={target}
        placement="top"
        style={{ color: "#7BCCD5" }}
      >
        {text}
      </Tooltip>
    );
  };

  useEffect(() => setSearch(""), [ location ]);

  if (!data || data?.length === 0) {
    return null;
  }

  return (
    <FilterContainer wide={customUrl ? true : false} modal={modal}>
      <SearchContainer modal={modal}>
        <SearchInput
          onChange={(e) => setSearch(e.target.value.toLowerCase())}
          type="text"
          placeholder={t("ibis-ui:filters.search_filters")}
        />
        {icons.searchIcon}
      </SearchContainer>
      <Scroll modal={modal}>
        <AmountOfFilters>
          {t("ibis-ui:filters.filters")}
          {badges?.options &&
            Object.keys(badges.options).length > 0 &&
            `(${Object.keys(badges.options).length})`}
        </AmountOfFilters>

        {badges?.options && Object.keys(badges?.options).length > 0 && (
          <BadgeContainer>
            {Object.entries(badges.options).map(([ badgeKey, badgeValue ]) => (
              <Badge
                key={badgeKey}
                id={`FilterName-${badgeValue?.label}`}
                onMouseEnter={() => {
                  checkTextOverflow(`FilterName-${badgeValue?.label}`);
                  toggle(`FilterName-${badgeValue?.label}`);
                }}
                onMouseLeave={() => toggle(null)}
                onClick={() => toggleSubmit(badgeValue?.action?.navigate?.path)}
                filterPanel
                activeFilters
                isAllUpperCase={
                  badgeValue?.label === badgeValue?.label.toUpperCase()
                }
                withIcon
              >
                {badgeValue?.label}
              </Badge>
            ))}
          </BadgeContainer>
        )}
        {!filteredData?.length &&
          <NoResultsMsg>{t("ibis-ui:no_results")}</NoResultsMsg>
        }
        <MainList>
          {filteredData?.map((filters, index) => (
            <li key={index} style={{ listStyle: "none" }}>
              <FilterType>{filters?.label}</FilterType>
              <FiltersList expanded={expanded[index]}>
                {Object.entries(filters?.options).map(([ filterKey, filterValue ], idx) => (
                  (idx <= 4 || (expanded[index])) && (
                    <FilterItem key={filterKey}>
                      <Item>
                        <Checkbox
                          ref={checkboxRef}
                          htmlFor={filterKey} 
                          name={filterKey}
                          id={filterKey}
                          checked={filterValue?.selected ?? false}
                          onChange={() => toggleSubmit(filterValue?.action?.navigate?.path)}
                          onKeyDown={(event) => handleKeyDown(event, filterKey)}
                        />
                        <FilterName
                          id={`FilterName-${filterValue?.count}`}
                          onMouseEnter={() => {
                            checkTextOverflow(`FilterName-${filterValue?.count}`);
                            toggle(`FilterName-${filterValue?.count}`);
                          }}
                          onMouseLeave={() => toggle(null)}
                          onClick={() =>
                            toggleSubmit(filterValue?.action?.navigate?.path)
                          }
                        >
                          {filterValue?.label || formatString(filters?.label)}
                        </FilterName>
                        {isTextOverflow &&
                          CustomTooltip(
                            `FilterName-${filterValue?.count}`,
                            filterValue?.count
                          )}
                      </Item>
                      <Badge filterPanel>{filterValue?.count}</Badge>
                    </FilterItem>
                  )
                ))}
              </FiltersList>
              {Object.keys(filters.options).length > 5 &&
                <ButtonContainer>
                  <ShowMore onClick={() => handleShowMore(index)}>
                    {expanded[index]
                      ? t("ibis-ui:filters.collapse")
                      : t("ibis-ui:filters.showMore", { count: Object.keys(filters?.options).length - 5 })
                    }
                  </ShowMore>
                </ButtonContainer>
              }
            </li>
          ))}
        </MainList>
      </Scroll>
    </FilterContainer>
  );
};

FilterPanel.propTypes = {
  customUrl: PropTypes.string,
  modal: PropTypes.bool,
  setTarget: PropTypes.string,
};

export default FilterPanel;
