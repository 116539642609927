import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { HashRouter } from 'react-router-dom';
import { useLocation } from 'react-router';

import MainDisplay from '../../../pages/mainDisplay';
import {
  StyledNodeSelectorList,
  StyledNodeSelectorContainer,
  StyledNodeSelectorDisplay,
} from './nodeSelector.styled';
import { NodeSelector, NodeName } from './nodeSelector';
import { useIBIS } from '../../../hooks/useIbis';

export const NodeSelectorContainer = ({ nestedLevels }) => {
  const [ selected, setSelected ] = useState(null);
  const IBIS = useIBIS();

  const location = useLocation();
  const hash = decodeURIComponent(location.hash);
  return (
    <StyledNodeSelectorContainer>
      <StyledNodeSelectorList>
        {IBIS.node.items.map((node) =>
          nestedLevels > 1 ? (
            <NodeSelector
              key={node.name}
              name={node.name}
              setSelected={() =>
                selected !== node.name
                  ? setSelected(node.name)
                  : setSelected(null)
              }
              isDirectory={node.type === "directory"}
              indentationLevel={1}
              unravel={selected ? selected === node.name : false}
            />
          ) : (
            <NodeName
              name={node.name}
              link={node.name + (node.type === "directory" ? "/" : "")}
            />
          )
        )}
      </StyledNodeSelectorList>
      <HashRouter hashType="noslash">
        <StyledNodeSelectorDisplay>
          {hash && <MainDisplay hashPathname={hash.replace("#", "/")} />}
        </StyledNodeSelectorDisplay>
      </HashRouter>
    </StyledNodeSelectorContainer>
  );
};

NodeSelectorContainer.propTypes = {
  nestedLevels: PropTypes.number,
};
