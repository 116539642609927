import { ReactComponent as Arrow } from '../../../assets/svg/icons/arrow.svg';
import { ReactComponent as Cross } from '../../../assets/svg/icons/cross.svg';
import { ReactComponent as Folder } from '../../../assets/svg/icons/folder.svg';
import { ReactComponent as Notes } from '../../../assets/svg/icons/notes.svg';
import { ReactComponent as Success } from '../../../assets/svg/icons/success.svg';
import { ReactComponent as Data } from '../../../assets/svg/icons/data.svg';
import { ReactComponent as Foldertransport } from '../../../assets/svg/icons/foldertransport.svg';
import { ReactComponent as Open } from '../../../assets/svg/icons/open.svg';
import { ReactComponent as Tile } from '../../../assets/svg/icons/tile.svg';
import { ReactComponent as Accept } from '../../../assets/svg/icons/accept.svg';
import { ReactComponent as Delete } from '../../../assets/svg/icons/delete.svg';
import { ReactComponent as Gear } from '../../../assets/svg/icons/gear.svg';
import { ReactComponent as Option } from '../../../assets/svg/icons/option.svg';
import { ReactComponent as Tiles } from '../../../assets/svg/icons/tiles.svg';
import { ReactComponent as AddFile } from '../../../assets/svg/icons/add-file.svg';
import { ReactComponent as Details } from '../../../assets/svg/icons/details.svg';
import { ReactComponent as Hardware } from '../../../assets/svg/icons/hardware.svg';
import { ReactComponent as Outofmemory2 } from '../../../assets/svg/icons/outofmemory-2.svg';
import { ReactComponent as Timeout } from '../../../assets/svg/icons/timeout.svg';
import { ReactComponent as Add } from '../../../assets/svg/icons/add.svg';
import { ReactComponent as Directory } from '../../../assets/svg/icons/directory.svg';
import { ReactComponent as HookDefinition } from '../../../assets/svg/icons/hook-definition.svg';
import { ReactComponent as Outofmemory } from '../../../assets/svg/icons/outofmemory.svg';
import { ReactComponent as ToastrError } from '../../../assets/svg/icons/toastr-error.svg';
import { ReactComponent as AskFor } from '../../../assets/svg/icons/ask-for.svg';
import { ReactComponent as Down } from '../../../assets/svg/icons/down.svg';
import { ReactComponent as Ibis } from '../../../assets/svg/icons/ibis.svg';
import { ReactComponent as Pdf } from '../../../assets/svg/icons/pdf.svg';
import { ReactComponent as ToastrSuccess } from '../../../assets/svg/icons/toastr-success.svg';
import { ReactComponent as AttributeDefinition } from '../../../assets/svg/icons/attribute-definition.svg';
import { ReactComponent as Download } from '../../../assets/svg/icons/download.svg';
import { ReactComponent as Image } from '../../../assets/svg/icons/image.svg';
import { ReactComponent as Permmission } from '../../../assets/svg/icons/permmission.svg';
import { ReactComponent as ToastrWarning } from '../../../assets/svg/icons/toastr-warning.svg';
import { ReactComponent as BxEdit } from '../../../assets/svg/icons/bx-edit.svg';
import { ReactComponent as Dropzone } from '../../../assets/svg/icons/dropzone.svg';
import { ReactComponent as Private } from '../../../assets/svg/icons/private.svg';
import { ReactComponent as Trash } from '../../../assets/svg/icons/trash.svg';
import { ReactComponent as Calculation } from '../../../assets/svg/icons/calculation.svg';
import { ReactComponent as Duplicate } from '../../../assets/svg/icons/duplicate.svg';
import { ReactComponent as Info } from '../../../assets/svg/icons/info.svg';
import { ReactComponent as Processor } from '../../../assets/svg/icons/processor.svg';
import { ReactComponent as Up1 } from '../../../assets/svg/icons/up-1.svg';
import { ReactComponent as Calendar } from '../../../assets/svg/icons/calendar.svg';
import { ReactComponent as EditPermissions } from '../../../assets/svg/icons/edit-permissions.svg';
import { ReactComponent as Information } from '../../../assets/svg/icons/information.svg';
import { ReactComponent as Question1 } from '../../../assets/svg/icons/question-1.svg';
import { ReactComponent as UpDown } from '../../../assets/svg/icons/up-down.svg';
import { ReactComponent as Cancel } from '../../../assets/svg/icons/cancel.svg';
import { ReactComponent as Edit } from '../../../assets/svg/icons/edit.svg';
import { ReactComponent as Job } from '../../../assets/svg/icons/job.svg';
import { ReactComponent as Question } from '../../../assets/svg/icons/question.svg';
import { ReactComponent as Up } from '../../../assets/svg/icons/up.svg';
import { ReactComponent as Check } from '../../../assets/svg/icons/check.svg';
import { ReactComponent as Error } from '../../../assets/svg/icons/error.svg';
import { ReactComponent as Json } from '../../../assets/svg/icons/json.svg';
import { ReactComponent as Queue } from '../../../assets/svg/icons/queue.svg';
import { ReactComponent as Url } from '../../../assets/svg/icons/url.svg';
import { ReactComponent as CheckMark } from '../../../assets/svg/icons/check-mark.svg';
import { ReactComponent as Executing } from '../../../assets/svg/icons/executing.svg';
import { ReactComponent as Left } from '../../../assets/svg/icons/left.svg';
import { ReactComponent as Refresh } from '../../../assets/svg/icons/refresh.svg';
import { ReactComponent as User } from '../../../assets/svg/icons/user.svg';
import { ReactComponent as Checker } from '../../../assets/svg/icons/checker.svg';
import { ReactComponent as External } from '../../../assets/svg/icons/external.svg';
import { ReactComponent as Link } from '../../../assets/svg/icons/link.svg';
import { ReactComponent as Rename } from '../../../assets/svg/icons/rename.svg';
import { ReactComponent as Warning } from '../../../assets/svg/icons/warning.svg';
import { ReactComponent as Clock } from '../../../assets/svg/icons/clock.svg';
import { ReactComponent as FileLocked } from '../../../assets/svg/icons/file-locked.svg';
import { ReactComponent as List } from '../../../assets/svg/icons/list.svg';
import { ReactComponent as Right } from '../../../assets/svg/icons/right.svg';
import { ReactComponent as Wiecej } from '../../../assets/svg/icons/wiecej.svg';
import { ReactComponent as Close } from '../../../assets/svg/icons/close.svg';
import { ReactComponent as File } from '../../../assets/svg/icons/file.svg';
import { ReactComponent as Logo } from '../../../assets/svg/icons/logo.svg';
import { ReactComponent as RoleAssignment } from '../../../assets/svg/icons/role-assignment.svg';
import { ReactComponent as Wstecz1 } from '../../../assets/svg/icons/wstecz-1.svg';
import { ReactComponent as Cluster } from '../../../assets/svg/icons/cluster.svg';
import { ReactComponent as Fill } from '../../../assets/svg/icons/fill.svg';
import { ReactComponent as Minus } from '../../../assets/svg/icons/minus.svg';
import { ReactComponent as RoleDefinition } from '../../../assets/svg/icons/role-definition.svg';
import { ReactComponent as Wstecz2 } from '../../../assets/svg/icons/wstecz-2.svg';
import { ReactComponent as Cluster2 } from '../../../assets/svg/icons/cluster-2.svg';
import { ReactComponent as Filter1 } from '../../../assets/svg/icons/filter-1.svg';
import { ReactComponent as More } from '../../../assets/svg/icons/more.svg';
import { ReactComponent as Schema } from '../../../assets/svg/icons/schema.svg';
import { ReactComponent as Wstecz } from '../../../assets/svg/icons/wstecz.svg';
import { ReactComponent as ColumnSorting } from '../../../assets/svg/icons/column-sorting.svg';
import { ReactComponent as Filter } from '../../../assets/svg/icons/filter.svg';
import { ReactComponent as Move } from '../../../assets/svg/icons/move.svg';
import { ReactComponent as Search } from '../../../assets/svg/icons/search.svg';
import { ReactComponent as Computer } from '../../../assets/svg/icons/computer.svg';
import { ReactComponent as Filtration } from '../../../assets/svg/icons/filtration.svg';
import { ReactComponent as NoPermmission } from '../../../assets/svg/icons/no-permmission.svg';
import { ReactComponent as Share } from '../../../assets/svg/icons/share.svg';
import { ReactComponent as CopyClipboard } from '../../../assets/svg/icons/copy-clipboard.svg';
import { ReactComponent as Finished } from '../../../assets/svg/icons/finished.svg';
import { ReactComponent as NoPublished } from '../../../assets/svg/icons/no-published.svg';
import { ReactComponent as Signet } from '../../../assets/svg/icons/signet.svg';
import { ReactComponent as Create } from '../../../assets/svg/icons/create.svg';
import { ReactComponent as FolderLocked } from '../../../assets/svg/icons/folder-locked.svg';
import { ReactComponent as Notes1 } from '../../../assets/svg/icons/notes-1.svg';
import { ReactComponent as Submited } from '../../../assets/svg/icons/submited.svg';
import { ReactComponent as Transition } from '../../../assets/svg/icons/transition.svg';
import { ReactComponent as Research } from '../../../assets/svg/icons/research.svg';
import { ReactComponent as Official } from '../../../assets/svg/icons/official.svg';
import { ReactComponent as MarkAsVerified } from '../../../assets/svg/icons/mark-as-verified.svg';
import { ReactComponent as MarkAsUnverified } from '../../../assets/svg/icons/mark-as-unverified.svg';
import { ReactComponent as GrantsAwarded } from '../../../assets/svg/icons/grants-awarded.svg';
import { ReactComponent as Dataspaces } from '../../../assets/svg/icons/dataspaces.svg';
import { ReactComponent as CrossIcon } from '../../../assets/svg/icons/cross-icon.svg';
import { ReactComponent as SearchIcon } from '../../../assets/svg/icons/search-icon.svg';
import { ReactComponent as AcceptActionIcon } from '../../../assets/svg/icons/accept-action-icon.svg';
import { ReactComponent as CancelActionIcon } from '../../../assets/svg/icons/cancel-action-icon.svg';
import { ReactComponent as NewSectionIcon } from '../../../assets/svg/icons/new-section-icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/svg/icons/edit-icon.svg';

// New approach to naming: 
// * file names, component,  
// * file name always with underscores
// * main key under which icon is accessible always the same as file name
// * component name the same as file but in pascal case
// * file name, main key and component name all describe what is directly visible on the icon
// * however icons may be exposed under additional aliases describing possible usage
//
// For example: icon with arrow pointing downward should be:
// * stored in file named arrow_down.svg
// * imported as component ArrowDown
// * exposed under key "arrow_down" and additional key: "download"

import { ReactComponent as ClipboardChecked } from '../../../assets/svg/icons/clipboard_checked.svg';
import { ReactComponent as LoupeChecked } from '../../../assets/svg/icons/loupe_checked.svg';
import { ReactComponent as SheetColumns } from '../../../assets/svg/icons/sheet_columns.svg';
import { ReactComponent as SheetCheckedTransparent } from '../../../assets/svg/icons/sheet_checked_transparent.svg';
import { ReactComponent as FolderCursor } from '../../../assets/svg/icons/folder_cursor.svg';
import { ReactComponent as SpinningArrows } from '../../../assets/svg/icons/spinning_arrows.svg';
import { ReactComponent as SpinningBullets } from '../../../assets/svg/icons/spinning_bullets.svg';
import { ReactComponent as RectanglesStack } from '../../../assets/svg/icons/rectangles_stack.svg';
import { ReactComponent as FramedPerson } from '../../../assets/svg/icons/framed_person.svg';
import { ReactComponent as FramedClock } from '../../../assets/svg/icons/framed_clock.svg';
import { ReactComponent as FramedKey } from '../../../assets/svg/icons/framed_key.svg';
import { ReactComponent as SpinAroundTransparent } from '../../../assets/svg/icons/spin_around_transparent.svg';
import { ReactComponent as DisksEmptyBeforeCrossed } from '../../../assets/svg/icons/disks_empty_before_crossed.svg';
import { ReactComponent as DisksEmptyBeforeFull } from '../../../assets/svg/icons/disks_empty_before_full.svg';

export const icons = {
  arrow: <Arrow />,
  cross: <Cross />,
  folder: <Folder />,
  notes: <Notes />,
  success: <Success />,
  data: <Data />,
  "folder-transport": <Foldertransport />,
  open: <Open />,
  tile: <Tile />,
  accept: <Accept />,
  delete: <Delete />,
  gear: <Gear />,
  option: <Option />,
  tiles: <Tiles />,
  "add-file": <AddFile />,
  details: <Details />,
  hardware: <Hardware />,
  "outofmemory-2": <Outofmemory2 />,
  timeout: <Timeout />,
  add: <Add />,
  directory: <Directory />,
  "hook-definition": <HookDefinition />,
  outofmemory: <Outofmemory />,
  "toastr-error": <ToastrError />,
  "ask-for": <AskFor />,
  down: <Down />,
  ibis: <Ibis />,
  pdf: <Pdf />,
  "toastr-success": <ToastrSuccess />,
  "attribute-definition": <AttributeDefinition />,
  download: <Download />,
  image: <Image />,
  permission: <Permmission />,
  "toastr-warning": <ToastrWarning />,
  "bx-edit": <BxEdit />,
  dropzone: <Dropzone />,
  private: <Private />,
  trash: <Trash />,
  calculation: <Calculation />,
  duplicate: <Duplicate />,
  info: <Info />,
  processor: <Processor />,
  "up-1": <Up1 />,
  calendar: <Calendar />,
  "edit-permissions": <EditPermissions />,
  information: <Information />,
  "question-1": <Question1 />,
  "up-down": <UpDown />,
  cancel: <Cancel />,
  edit: <Edit />,
  job: <Job />,
  question: <Question />,
  up: <Up />,
  check: <Check />,
  error: <Error />,
  json: <Json />,
  queue: <Queue />,
  url: <Url />,
  "check-mark": <CheckMark />,
  executing: <Executing />,
  left: <Left />,
  refresh: <Refresh />,
  user: <User />,
  checker: <Checker />,
  external: <External />,
  link: <Link />,
  rename: <Rename />,
  warning: <Warning />,
  clock: <Clock />,
  "file-locked": <FileLocked />,
  list: <List />,
  right: <Right />,
  wiecej: <Wiecej />,
  close: <Close />,
  file: <File />,
  logo: <Logo />,
  "role-assignment": <RoleAssignment />,
  "wstecz-1": <Wstecz1 />,
  cluster: <Cluster />,
  fill: <Fill />,
  minus: <Minus />,
  "role-definition": <RoleDefinition />,
  "wstecz-2": <Wstecz2 />,
  "cluster-2": <Cluster2 />,
  "filter-1": <Filter1 />,
  more: <More />,
  schema: <Schema />,
  wstecz: <Wstecz />,
  "column-sorting": <ColumnSorting />,
  filter: <Filter />,
  move: <Move />,
  search: <Search />,
  computer: <Computer />,
  filtration: <Filtration />,
  "no-permmission": <NoPermmission />,
  share: <Share />,
  "copy-clipboard": <CopyClipboard />,
  finished: <Finished />,
  "no-published": <NoPublished />,
  signet: <Signet />,
  create: <Create />,
  "folder-locked": <FolderLocked />,
  "notes-1": <Notes1 />,
  submited: <Submited />,
  Directory: <Directory />,
  JSON: <Json />,
  FILE: <File />,
  PDF: <Pdf />,
  LINK: <Link />,
  config: <Gear />,
  "dot-ibis": <b>.ibis</b>,
  transition: <Transition />,
  research: <Research />,
  official: <Official />,
  "mark-as-verified": <MarkAsVerified />,
  "mark-as-unverified": <MarkAsUnverified />,
  "grants-awarded": <GrantsAwarded />,
  dataspaces: <Dataspaces />,
  crossIcon: <CrossIcon />,
  searchIcon: <SearchIcon />,
  editIcon: <EditIcon />,
  acceptActionIcon: <AcceptActionIcon />,
  cancelActionIcon: <CancelActionIcon />,
  newSectionIcon: <NewSectionIcon />,

  clipboard_checked: <ClipboardChecked />,
  loupe_checked: <LoupeChecked />,
  sheet_columns: <SheetColumns />,
  sheet_checked_transparent: <SheetCheckedTransparent />,
  folder_cursor: <FolderCursor />,
  spinning_arrows: <SpinningArrows />,
  spinning_bullets: <SpinningBullets />,
  rectangles_stack: <RectanglesStack />,
  framed_person: <FramedPerson />,
  framed_clock: <FramedClock />,
  framed_key: <FramedKey />,
  spin_around_transparent: <SpinAroundTransparent />,
  disks_empty_before_crossed: <DisksEmptyBeforeCrossed />,
  disks_empty_before_full: <DisksEmptyBeforeFull />,
};
