import React from 'react';
import PropTypes from 'prop-types';

import { TooltipContainer, TooltipText } from './CustomPieChart';
import backendTranslation from '../../../main/utils/backendTranslation';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        <TooltipText>{backendTranslation(payload[0]?.name)}: {payload[0]?.value}</TooltipText>
      </TooltipContainer>
    );
  }

  return null;
};

export default CustomTooltip;

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array
};