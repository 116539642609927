import React from 'react';
import { useHistory } from 'react-router-dom';

import useIBIS from '../../hooks/useIbis';
import { parseHints } from '../../utils/hints';
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from 'recharts';
import backendTranslation from './../../../main/utils/backendTranslation';
import { COLORS } from './Colors';
import { AnimateSlideDown, ChartContainer } from './CustomPieChart';
import CustomTooltip from './CustomTooltip';

const CustomPieChart = () => {
  const history = useHistory();
  const IBIS = useIBIS(false);
  const queryParams = new URLSearchParams(window.location.search);
  const staticData = parseHints(IBIS?.node?.hints?.graphbar?.graphs) || [];
  const dynamicData = IBIS?.node?.hints?.navbar?.graphs ? parseHints(IBIS?.node?.hints?.navbar?.sections) : [];
  const isHeader = queryParams.get("addressbar") === "false" 
    || queryParams.get("infobar") === "false"
    || [ "hidden", "collapsed" ].includes(IBIS.node?.hints?.addressbar?.visibility);

  const filterDataWithOptions = (data) => 
    data.filter(ele => 
      ele?.graph !== 'disabled' 
      && typeof ele?.options === 'object' 
      && ele?.options !== null 
      && Object.keys(ele?.options).length > 0 
      && (!ele?.type || ele?.type === "enum_panel")
    );
  
  const data = [
    ...filterDataWithOptions(Array.isArray(dynamicData) ? dynamicData : []),
    ...filterDataWithOptions(Array.isArray(staticData) ? staticData : []),
  ];

  const processData = (options) => Object.entries(options).map(([ key, option ]) => ({
    name: backendTranslation(option?.label),
    value: option?.count,
    action: option?.action?.navigate?.path
  }));

  const toggleSubmit = (path) => {
    if (!path) {
      return null;
    }
    else {
      history.push(path);
    }
  };

  if (!data?.length) {
    return null;
  };

  return (
    <AnimateSlideDown>
      { data?.slice(0, 4)?.map((ele, id) => (
        <ChartContainer key={`container-${id}`} isLabel={ele?.label} isHeader={isHeader}>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={128} height={128}>
              <Pie 
                stroke='none'
                dataKey="value"
                isAnimationActive={false}
                data={processData(ele?.options)}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={70}
                fill="#8884d8"
                onClick={(data, index) => {
                  const actionPath = processData(ele?.options)[index]?.action;
                  if (actionPath) {
                    toggleSubmit(actionPath);
                  }
                }}
              >
                {processData(ele?.options)?.map((entry, index) => (
                  <Cell 
                    key={`cell-${index}`} 
                    fill={COLORS[(id * 8 + index) % COLORS.length]}
                    style={{ cursor: entry?.action ? "pointer" : "default" }}
                  />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </ResponsiveContainer>
          {ele?.label && <span>{ele?.label}</span>}
        </ChartContainer>
      )
      )}
    </AnimateSlideDown>
  );
};

export default CustomPieChart;