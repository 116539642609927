import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  ButtonAction,
  ButtonContainer,
  ContainerInput,
  CustomButton,
  CustomInput,
  CustomLink,
  ErrorMsg,
  IconWrapper,
  NewSection,
  StyledBreadCrumbs,
} from './breadCrumbs.styled';
import { icons } from '../../../../../assets/svg/icons';

const BreadCrumbs = ({ setStartPath, startPath }) => {
  const splitPath = startPath
    ? startPath.split("/").slice(1)
    : decodeURIComponent(window.location.pathname).split("/").slice(1);
  const segmentIndexToReplace = splitPath[splitPath.length - 1] === "" ? 2 : 1;

  const { t } = useTranslation();
  const history = useHistory();
  const inputRef = useRef(null);
  const newSectionRef = useRef(null);
  const containerRef = useRef();

  const [ editPath, setEditPath ] = useState(
    splitPath[splitPath.length - segmentIndexToReplace]
  );
  const [ errorEditSection, setErrorEditSection ] = useState("");
  const [ errorNewSection, setErrorNewSection ] = useState("");
  const [ isEditPath, setIsEditPath ] = useState(null);
  const [ newSection, setNewSection ] = useState(false);
  const [ newPath, setNewPath ] = useState("");

  const updateServiceName = (editValue, newSection, setError, index) => {
    if (/[ąćęłńóśźż]/i.test(editValue)) {
      setError(t("ibis-ui:breadcrumbs.not_polish_characters"));
      return;
    }

    if (editValue.length > 64) {
      setError(t("ibis-ui:breadcrumbs.limit_characters"));
      return;
    }

    if (/\s/.test(editValue)) {
      setError(t("ibis-ui:breadcrumbs.not_space"));
      return;
    }
    if (editValue.trim() === "") {
      setError(t("ibis-ui:breadcrumbs.is_empty"));
      return;
    }

    if (editValue && newSection) {
      history.push(editValue);
      setNewSection(false);
      setIsEditPath(null);
      setError("");
    }
    else {
      let path = splitPath[index];
      const newUrl = decodeURIComponent(history.location.pathname).replace(
        path,
        editValue
      );
      history.replace(newUrl);
      setIsEditPath(null);
      setNewSection(false);
      setError("");
      setNewPath("");
    }
  };

  const closeModify = (isOpen, setError, setEditValue, newSection) => {
    isOpen(false);
    setError("");
    newSection
      ? setEditValue("")
      : setEditValue(splitPath[splitPath.length - segmentIndexToReplace]);
  };
  const getNavigationPath = (index, level) => {
    return (
      (index > 1 ? "/" : "") +
      splitPath.slice(1, index).join("/") +
      `${index > 0 && level ? `/${level}` : ""}` +
      (index < splitPath.length - 1 ? "/" : "") +
      window.location.search
    );
  };

  const modifySection = (
    isOpen,
    editValue,
    setEditValue,
    error,
    setError,
    inputRef,
    newSection,
    index
  ) => {
    return (
      <ContainerInput>
        <CustomInput
          error={error}
          ref={inputRef}
          type="text"
          value={editValue}
          onChange={(e) => setEditValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter")
              updateServiceName(editValue, newSection, setError, index);
            if (e.key === "Escape")
              closeModify(isOpen, setError, setEditValue, newSection);
          }}
        />
        <IconWrapper>{icons.editIcon}</IconWrapper>
        {error && <ErrorMsg>{error}</ErrorMsg>}
        <ButtonContainer>
          <ButtonAction
            onClick={() =>
              updateServiceName(editValue, newSection, setError, index)
            }
          >
            {icons.acceptActionIcon}
          </ButtonAction>
          <ButtonAction
            onClick={() =>
              closeModify(isOpen, setError, setEditValue, newSection)
            }
          >
            {icons.cancelActionIcon}
          </ButtonAction>
        </ButtonContainer>
      </ContainerInput>
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        if (isEditPath !== null) {
          setIsEditPath(null);
          setErrorEditSection("");
        }
        if (newSection) {
          setNewSection(false);
          setErrorNewSection("");
        }
      }
    };

    const handleEscapePress = (event) => {
      if (event.key === "Escape") {
        if (isEditPath !== null) {
          setIsEditPath(null);
          setErrorEditSection("");
        }
        if (newSection) {
          setNewSection(false);
          setErrorNewSection("");
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapePress);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapePress);
    };
  }, [ isEditPath, newSection ]);

  useEffect(() => {
    setEditPath(
      decodeURIComponent(window.location.pathname).split("/").slice(1)[
        splitPath.length - segmentIndexToReplace
      ]
    );
    setErrorEditSection("");
    setErrorNewSection("");
    setNewPath("");
    setIsEditPath(null);
    setNewSection(false);
  }, [ splitPath.length, segmentIndexToReplace ]);

  useEffect(() => {
    if (isEditPath) {
      inputRef?.current?.focus();
      setNewSection(false);
    }
  }, [ isEditPath ]);

  useEffect(() => {
    if (newSection) {
      newSectionRef.current.focus();
      setIsEditPath(null);
    }
  }, [ newSection ]);

  return (
    <StyledBreadCrumbs ref={containerRef}>
      <li key={window.location.hostname}>
        <p style={{ color: "var(--primary-500)", margin: 0 }}>
          {window.location.hostname}
        </p>
      </li>
      {splitPath?.map((level, index) => {
        return (
          <li
            key={level + index}
            style={{ display: "flex", alignItems: "center" }}
          >
            <span className="mx-2">/</span>
            {isEditPath === index && level ? (
              <>
                {modifySection(
                  setIsEditPath,
                  editPath,
                  setEditPath,
                  errorEditSection,
                  setErrorEditSection,
                  inputRef,
                  null,
                  index
                )}
              </>
            ) : (
              <CustomButton>
                {setStartPath ? (
                  <div
                    onClick={() =>
                      setStartPath(getNavigationPath(index, level))
                    }
                  >
                    {level}
                  </div>
                ) : (
                  <CustomLink
                    iscursor={
                      (splitPath.length - segmentIndexToReplace !== index) ? 'true' : undefined
                    }
                    to={getNavigationPath(index, level)}
                  >
                    {level === ' ' ? <span>&nbsp;</span> : level}
                  </CustomLink>
                )}
                <div
                  className="icon"
                  onClick={() => {
                    setIsEditPath(index);
                    setEditPath(splitPath[index]);
                  }}
                >
                  {icons.editIcon}
                </div>
              </CustomButton>
            )}
          </li>
        );
      })}
      {splitPath[splitPath.length - 1] === "" && (
        <>
          {!newSection ? (
            <NewSection onClick={() => setNewSection(true)}>
              {icons.newSectionIcon}
            </NewSection>
          ) : (
            modifySection(
              setNewSection,
              newPath,
              setNewPath,
              errorNewSection,
              setErrorNewSection,
              newSectionRef,
              newSection
            )
          )}
        </>
      )}
    </StyledBreadCrumbs>
  );
};

BreadCrumbs.propTypes = {
  setStartPath: PropTypes.func,
  startPath: PropTypes.string,
};

export default BreadCrumbs;
