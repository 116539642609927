import styled, { css, keyframes } from 'styled-components';
import { theme } from '../../styles/index';

const checkmarkAnimation = keyframes`
  from { opacity: 0; transform: rotate(45deg) scale(0); }
  to { opacity: 1; transform: rotate(45deg) scale(1); }
`;

const selectedAllAnimation = keyframes`
  from { opacity: 0; transform: scale(0); }
  to { opacity: 1; transform: scale(1); }
`;

export const CheckboxContainer = styled.div`
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  position: absolute;
  width: 16px;
  height: 16px;
  cursor: pointer;
  &:focus-visible + label {
    box-shadow: 0px 0px 0px 4px #5E5ADB66;
  }
`;

export const StyledLabel = styled.label`
  margin: 0;
  position: relative;
  width: 16px;
  height: 16px;
  cursor: pointer;
  display: block;
  border: none;
  border-radius: 4px;
  box-shadow: ${({ checked }) => (checked ? "transparent" : '0px 0px 0px 1px #464F6029, 0px 1px 1px 0px #0000001A')};
  background: ${({ checked }) => (checked ? theme.colors.primary["500"] : 'transparent')};

  &::after {
    content: '';
    position: absolute;
    width: ${({ isSomeRowsSelected }) => isSomeRowsSelected ? "10px" : "4px"};
    height: ${({ isSomeRowsSelected }) => isSomeRowsSelected ? "2px" : "8px"};
    top: ${({ isSomeRowsSelected }) => isSomeRowsSelected ? "50%" : "3px"};
    left: ${({ isSomeRowsSelected }) => isSomeRowsSelected ? "50%" : "6px"};
    border-right: ${({ isSomeRowsSelected }) => isSomeRowsSelected ? "none" : "2px solid white"};
    border-bottom: ${({ isSomeRowsSelected }) => isSomeRowsSelected ? "none" : "2px solid white"};
    border-radius: ${({ isSomeRowsSelected }) => isSomeRowsSelected ? "1px" : "0"};
    background-color: ${({ isSomeRowsSelected }) => isSomeRowsSelected ? "white" : "transparent"};
    opacity: ${({ checked }) => checked ? "1" : '0'};
    transform: ${({ checked, isSomeRowsSelected }) => checked 
    ? (isSomeRowsSelected ? "translate(-50%, -50%) scale(1)" : "rotate(45deg) scale(1)") 
    : "translate(-50%, -50%) scale(0)"};
    transition: opacity 0.3s ease, transform 0.3s ease;

    animation: ${({ notWasChecked, isSomeRowsSelected }) => notWasChecked 
    ? css`${isSomeRowsSelected ? selectedAllAnimation : checkmarkAnimation} .3s ease forwards` 
    : 'none'};
  }
`;