import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';

import { NodesListing } from './nodesListing/nodesListing';
import { NodeSelectorContainer } from './nodeSelector/nodeSelectorContainer';
import Image from './file/image';
import Text from './file/text';
import Markdown from './file/markdown';
import JSON from './file/json';
import Form from './file/rsjf';
import Embed from './file/embed';
import Frame from './file/frame';
import { viewsLabels } from '../../components/hints/views';
import PDF from './file/pdf';

const Node = ({
  node,
  fetchMore,
  view,
  mode,
  isEditing,
  startPath,
  saveFile,
  getEmbedContent,
  isLoading,
  editorRef,
  submitRef,
}) => {
  const dummyRef = useRef(null);
  const monacoRef = editorRef ?? dummyRef;
  const { t } = useTranslation();

  return (
    <Row className="h-100 p-0 m-0">
      <Col className="p-0">
        {node.content != null ? (
          (() => {
            switch (view.type) {
            case viewsLabels.IMAGE:
              return <Image content={node.content} />;
            case viewsLabels.MARKDOWN:
              return (
                <Markdown
                  content={node.content}
                  editorRef={monacoRef}
                  isEditing={isEditing}
                />
              );
            case viewsLabels.JSON:
              return (
                <JSON
                  content={node.content}
                  editorRef={monacoRef}
                  isEditing={isEditing}
                />
              );
            case viewsLabels.FORM:
              return (
                <Form
                  content={node.content}
                  onSubmit={saveFile}
                  node={node}
                  isEditing={isEditing}
                  submitRef={submitRef}
                />
              );
            case viewsLabels.TEXT:
              return (
                <Text
                  content={node.content}
                  editorRef={monacoRef}
                  isEditing={isEditing}
                />
              );
            case viewsLabels.EMBED:
              return (
                <Embed
                  content={node.content}
                  location={node.location}
                  editorRef={monacoRef}
                  isEditing={isEditing}
                  getEmbedContent={getEmbedContent}
                  isLoading={isLoading}
                />
              );
            case viewsLabels.PDF:
              return (
                <PDF
                  content={node.content}
                />
              );
            default:
              return <h4 className="p-3">{t("ibis-ui:cannot_display")}</h4>;
            }
          })()
        ) 
          : node.items && (
            (() => {
              switch (view.type) {
              case viewsLabels.SELECTOR:
                return (
                  <NodeSelectorContainer
                    startPath={startPath}
                    nestedLevels={node?.ui?.view?.types?.[0]?.view?.type?.selector?.depth}
                  />
                );
              case viewsLabels.LISTING:
                return (
                  <NodesListing
                    startPath={startPath}
                    resources={node.items}
                    loadMore={fetchMore}
                    mode={mode}
                  />
                );
              case viewsLabels.FRAME:
                return <Frame view={view} />;
              default:
                return <h4 className="p-3">{t("ibis-ui:cannot_display")}</h4>;
              }
            })()
          )
        }
      </Col>
    </Row>
  );
};

Node.propTypes = {
  node: PropTypes.object,
  fetchMore: PropTypes.func,
  view: PropTypes.object,
  mode: PropTypes.object,
  isEditing: PropTypes.bool,
  startPath: PropTypes.string,
  saveFile: PropTypes.func,
  getEmbedContent: PropTypes.func,
  isLoading: PropTypes.bool,
  editorRef: PropTypes.object,
  submitRef: PropTypes.object,
};

export default Node;