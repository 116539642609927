import React, { useRef, useState } from 'react';

import { useIBIS } from '../../hooks/useIbis';
import { Button } from '../button/button';
import { icons } from '../../../assets/svg/icons';
import { parseHints } from '../../utils/hints';
import { directoryCreatorModes } from '../nodeCreator/directoryCreator';
import DirectoryCreatorForm from '../nodeCreator/directoryCreatorForm';
import SymlinkCreator from '../nodeCreator/symlinkCreator';
import BasicControls from '../hints/basicControls';
import backendTranslation from '../../../main/utils/backendTranslation';

const Opsbar = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const disableOpsbar = queryParams.get("opsbar") === "false";

  const actionData = useRef();
  const [ directoryModal, setDirectoryModal ] = useState(null);
  const [ symlinkModal, setSymlinkModal ] = useState(null);

  const IBIS = useIBIS(false);
  if (
    IBIS.node?.hints?.opsbar?.visibility === "hidden" ||
    IBIS.node?.hints?.opsbar?.visibility === "collapsed"
  )
    return null;

  const controls = parseHints(IBIS.node?.hints?.opsbar?.controls);
  if (disableOpsbar || !controls) return null;

  const handleClickButton = (control) => {
    if (Object.keys(control.action)[0] === "mklink") {
      actionData.current = {
        atr: "mklink",
        bar: "opsbar",
        title: backendTranslation(control.label),
        path: control?.path
      };
      setSymlinkModal(control.action.mklink);
    }
    if (Object.keys(control.action)[0] === "mkdir") {
      actionData.current = { 
        atr: "mkdir", 
        bar: "opsbar", 
        title: backendTranslation(control.label), 
        data: control.action.mkdir?.attributes, 
        path: control.action.mkdir?.path 
      };
      setDirectoryModal(directoryCreatorModes.add);
    }
    if (Object.keys(control.action)[0] === "change") {
      actionData.current = {
        atr: "change",
        bar: "opsbar",
        title: backendTranslation(control.label),
        data: control.action.change?.attributes,
      };
      setDirectoryModal(directoryCreatorModes.edit);
    }
    else {
      IBIS.controls(control.action);
    }
  };

  return (
    <>
      {controls.map((control, index) => {
        return (
          <Button
            key={index}
            icon={icons[control.icon]}
            className="mr-2"
            onClick={() => handleClickButton(control)}
            disabled={IBIS.isLoading}
            notFlash
          >
            {backendTranslation(control.label)}
          </Button>
        );
      })}
      <DirectoryCreatorForm
        modal={directoryModal}
        closeModal={() => setDirectoryModal(null)}
        mode={directoryModal}
        actionData={actionData.current}
      />
      <SymlinkCreator
        modal={symlinkModal}
        closeModal={() => setSymlinkModal(null)}
        filter={symlinkModal}
        actionData={actionData.current}
      />
      <BasicControls />
    </>
  );
};

export default Opsbar;
