import React from 'react';
import PropTypes from 'prop-types';

import {
  CheckboxContainer,
  HiddenCheckbox,
  StyledLabel
} from './checkbox.js';

const Checkbox = ({ checked, htmlFor, id, name, onChange, onKeyDown, ref }) => (
  <CheckboxContainer>
    <HiddenCheckbox
      ref={ref}
      id={id}
      name={name}
      checked={checked}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
    <StyledLabel
      htmlFor={htmlFor} 
      checked={checked}
      onKeyDown={onKeyDown}
    />
  </CheckboxContainer>
);

Checkbox.defaultProps = {
  size: 'sm',
  outline: false
};

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  htmlFor: PropTypes.func,
  id: PropTypes.func,
  name: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  ref: PropTypes.bool
};

export default Checkbox;
