import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';

const PDF = ({ content }) => {
  const zoomPluginInstance = zoomPlugin();
  const fullScreenPluginInstance = fullScreenPlugin();
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      fullScreenPlugin: {
        getFullScreenTarget: (pagesContainer) => pagesContainer.closest('[data-testid="default-layout__body"]'),
        renderExitFullScreenButton: (props) => <></>,
      },
    },
  });

  const [ pdfUrl, setPdfUrl ] = useState('');

  useEffect(() => {
    const blob = new Blob([ content ], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    setPdfUrl(url);

    return () => URL.revokeObjectURL(url);
  }, [ content ]);

  const handleDocumentLoad = () => {
    const timeout = setTimeout(() => {
      zoomPluginInstance.zoomTo(1.3);
    }, 50);

    return () => clearTimeout(timeout);
  };

  return (
    <div style={{ height: 'calc(100vh - 216px)', width: '100%', padding: "0 31px 16px 16px" }}>
      <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`}>
        <div style={{ height: '100%', width: '100%' }}>
          <Viewer 
            fileUrl={pdfUrl} 
            plugins={[ defaultLayoutPluginInstance, zoomPluginInstance, fullScreenPluginInstance ]}
            onDocumentLoad={handleDocumentLoad}
          />
        </div>
      </Worker>
    </div>
  );
};

PDF.propTypes = {
  content: PropTypes.object
};

export default PDF;